// ==================================================
//  Mainvisual
// ==================================================
// スライダー
document.addEventListener("DOMContentLoaded", function () {
  var splideMainvisual = new Splide(".js-splide-mainvisual", {
    type: "fade",
    rewind: true,
    arrows: false,
    pagination: false,
    autoWidth: true,
    autoHeight: true,
    drag: false,
    autoplay: true,
    interval: 6000,
    speed: 1600,
    pauseOnHover: false,
    pauseOnFocus: false,
  });
  splideMainvisual.mount();
});

// Pick Up（PC、表示アニメーション）
document.addEventListener("DOMContentLoaded", function () {
  const pickUpElement = document.querySelector(".js-pickup");
  const loadedAnimation = () => {
    pickUpElement.classList.add("js-pickup-active");
  };
  setTimeout(loadedAnimation, 1.0 * 600);
});
